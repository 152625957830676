import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Clue3 = () => (
  <Layout>
    <Header />  

    <header className="masthead3">
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Clue #3</h1>
          <br></br>
          <br></br>
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
          Here you will find.
          <br></br>
          a court of all love
          <br></br>
          <br></br>          
          Smiles and laughter
          <br></br>
          with 'Sunshine' above. 
          <br></br>
          <br></br>
          When you are up
          <br></br>
          you may be all nerves.
          <br></br>
          <br></br>
          Just breathe and relax
          <br></br>
          it is your turn to Serve.
          <br></br>
          <br></br>
          For your next clue
          <br></br>
          the place you must get.
          <br></br>
          <br></br>
          Has a tall fence 
          <br></br>
          and is split with a net.
          </h2>
          {/* <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary">
              Hint
            </a>
          </Scroll> */}
            <a href="/hint3" className="btn btn-primary">
              Hint
            </a>  
          <br></br>
          <br></br>
          <br></br>
          <br></br>         
        </div>
      </div>
    </header>

    {/* <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="font-weight-bold text-white mb-4"></h2>
            <p className="text-white-50">
            </p>
          </div>
        </div>
        <img src={ipad} className="img-fluid" alt="" />
      </div>
    </section> */}

    {/* <Subscribe /> */}

    {/* <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Clue3;
